<template>

  <ContainerImgView ref="ContainerImgViewModal" @imgDeleted="updateTable" :images="container_img.images"
                    :container_id="container_img.container_id"/>
  <ContainerImgCreate ref="ContainerImgCreateModal" @update="updateTable()"/>
  <ContainerDocCreate :doc_id="row_id" @update="updateTable()"/>
  <ContainerUpdate @update="updateTable()" :container="container_update"/>
  <ContainerDelete @update="updateTable()" ref="containerDelete"/>
  <ContainerCreate @update="updateTable()"/>

  <div v-if="show_table" class="mb-4">
    <Table2 ref="terminalTable" :name="table.name" :url="table.url" :getUpdate="table.getUpdate"
            :headers="table.headers" :searchable="table.searchable" :selectable="true" @onRowSelect="onRowSelect">

      <template v-slot:header_div>
        <div class="mt-3 d-flex gap-2 flex-wrap">
          <h5 @click="filterByStatus('available')" class="mb-0 cursor-pointer">
            <span class="badge" :class="{
    'badge-soft-secondary': this.$route.query.container_status !== 'available',
    'bg-secondary': this.$route.query.container_status === 'available',
  }">Available</span>
          </h5>
          <h5 @click="filterByStatus('pending')" class="mb-0 cursor-pointer">
            <span class="badge" :class="{
    'badge-soft-warning': this.$route.query.container_status !== 'pending',
    'bg-warning': this.$route.query.container_status === 'pending',
  }">Pending</span>
          </h5>
          <h5 @click="filterByStatus('dispatched')" class="mb-0 cursor-pointer">
            <span class="badge" :class="{
    'badge-soft-success': this.$route.query.container_status !== 'dispatched',
    'bg-success': this.$route.query.container_status === 'dispatched',
  }">Dispatched</span>
          </h5>
        </div>
      </template>

      <template v-slot:top-right>

        <div class="d-flex align-items-center justify-content-between gap-5">
          <div class="d-flex gap-3">

            <TerminalActions ref="terminalActions" @updateTable="updateTable()"/>

            <b-button data-bs-toggle="offcanvas" data-bs-target="#TelegramHistoryCanvas"
                      aria-controls="TelegramHistoryCanvas">
              <i class=" bx bxl-telegram me-2 align-middle fs-5"></i>
              Telegram History
            </b-button>
            <b-button data-bs-target="#ContainerCreate" data-bs-toggle="modal" variant="success">Create Container
            </b-button>
          </div>
        </div>

      </template>
      <template v-slot:order="{ row: terminal }">
        <span v-if="terminal.order">
          <router-link :to='order_child_types[terminal.order.child_type] + terminal.order.number'>
            {{ terminal.order.number }}
          </router-link>
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:container="{ row: terminal }">
        {{ terminal.container.name || '--' }}
      </template>
      <template v-slot:container_type="{ row: terminal }">
        {{ terminal.container.type || '--' }}
      </template>
      <template v-slot:wagon="{ row: terminal }">
        {{ terminal.wagon || '--' }}
      </template>
      <template v-slot:arrival_date="{ row: terminal }">
        {{ terminal.arrival_date || '--' }}
      </template>
      <template v-slot:dispatch_date="{ row: terminal }">
        {{ terminal.dispatch_date || '--' }}
      </template>
      <template v-slot:is_laden="{ row: data }">
        <span v-if="data.is_laden" class="text-success fs-4">
          <i class="bx bx-check"></i>
        </span>
        <span v-else class="text-danger fs-4">
          <i class="bx bx-x"></i>
        </span>
      </template>
      <template v-slot:terminal="props">
        <span class="badge bg-primary">{{ props.row.terminal.name }}</span>
      </template>
      <template v-slot:container_status="props">
        <span class="badge" :class="{
    'badge-outline-warning': props.row.container_status === 'pending',
    'badge-outline-primary': props.row.container_status === 'available',
    'badge-outline-success': props.row.container_status === 'dispatched',
  }">{{ props.row.container_status }}</span>
      </template>

      <template v-slot:customers="{row: container}">
        <div class="d-flex flex-column gap-1" v-if="(container.customers || []).length > 0">
          <template v-for="customer in (container.customers || [])" :key="`customer${customer}`">
            <router-link
                v-b-tooltip.hover :title="customer.name"
                :to="{name: 'customer_profile', params: {slug: customer.slug}}">
              <h6 class="link-primary mb-0 text-truncate">{{ customer.name }}</h6>
            </router-link>
          </template>
        </div>
        <span v-else>--</span>
      </template>

      <template v-slot:storage_cost="{row: container}">

        <div v-if="container.number_of_days">
          <div class="d-block">
            {{ container.number_of_days || 0 }} {{ (container.number_of_days || 0) > 1 ? 'days' : 'day' }} in
            <span class="link-success fw-medium">{{ container.terminal.name }}</span>
          </div>

          <!-- Case: Container is not charged yet and it has free days remaining in terminal -->
          <div v-if="container.free_days_left > 0 || (container.number_of_days || 0) === container.terminal.free_days">
            <span :class="{
              'link-success': container.free_days_left > 3,
              'link-warning': container.free_days_left <= 3,
              'link-danger': container.free_days_left <= 1
            }">
              {{ container.free_days_left }} {{ container.free_days_left > 1 ? 'free days' : 'free day' }}
            </span> remaining
          </div>

          <!-- Case: Free days for the container has ended and system started charging it accordingly -->
          <div v-else-if="container.free_days_left <= 0">
            {{ formatPrice(container.storage_cost || 0) }}
            <span class="text-muted">(for {{
                calculateChargedDays(container)
              }} {{ calculateChargedDays(container) > 1 ? 'days' : 'day' }})</span>
          </div>
        </div>

        <div v-else>
          <span class="text-danger">No data</span>
        </div>

      </template>

      <template v-slot:additional_cost="props">
        {{ formatPrice(props.row.additional_cost) }}
      </template>
      <template v-slot:total_cost="props">
        <span :class="{
              'text-danger': (props.row.total_cost || 0) > 0,
            }" v-b-tooltip.hover
              :title="`${formatPrice(props.row.storage_cost || 0)} + ${formatPrice(props.row.additional_cost || 0)}`">
        {{ formatPrice(props.row.total_cost) }}
        </span>
      </template>
      <template v-slot:train="props">
        <span v-if="props.row.train">
          <router-link :to="{ name: 'train_details_main', params: { train_slug: props.row.train.slug } }">
            {{ props.row.train.name }}
          </router-link>
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:seal_images="props">
        <div class="d-inline-flex align-items-center">
          <div v-if="props.row.seal_images.length > 0">
            <span @click="viewContainerImg(props.row)">
              <i class="bx bx-file fs-20 align-middle text-primary c_icon_hoverable"></i>
            </span>
          </div>
          <i @click="this.$refs.ContainerImgCreateModal.openModal(props.row.id)"
             class="bx bx-plus-circle fs-20 text-success c_icon_hoverable ms-1">
          </i>
        </div>
      </template>
      <template v-slot:documents="props">
        <div class="d-inline-flex align-items-center">
          <div class="dropdown py-0 align-middle" v-if="props.row.documents.length > 0">
            <button type="button" class="btn btn-icon" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
              <i class="bx bx-file fs-20 text-primary align-middle"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end pb-0">
              <table class="table mb-0">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr class="align-middle" v-for="(doc, i) in props.row.documents" :key="'doc_' + doc.file">
                  <th>
                    {{ i + 1 }}
                  </th>
                  <td>
                    {{ doc.file.split('/').pop() }}
                  </td>
                  <td>
                    <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
                      <b-button @click="downloadDocument(doc.file)" variant="light" class="p-0">
                        <div class="px-1">
                          <i class="ri-download-fill align-bottom px-1"></i>
                        </div>
                      </b-button>
                      <b-button @click="deleteDocument(doc.id)" variant="light">
                        <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i @click="row_id = props.row.id" data-bs-target="#ContainerDocCreate" data-bs-toggle="modal"
             class="bx bx-plus-circle fs-20 text-success c_icon_hoverable"></i>
        </div>
      </template>
      <template v-slot:application_bot="{ row: bot }">
        <span v-if="bot.application_bot" @click="downloadDocument(bot.application_bot)">
          <i class="bx bx-file fs-5 text-primary align-bottom c_icon_hoverable"></i>
        </span>
      </template>
      <template v-slot:container_owner="{ row: terminal }">
        <span v-if="terminal.container_owner && terminal.container_owner.length > 0">
          {{ terminal.container_owner.map(owner => owner.name).join(', ') }}
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:clients="{ row: terminal }">
        <div v-if="terminal.clients.length > 0" class=" d-inline-flex align-items-center
                ">
          <div class="dropdown py-0">
            <button type="button" class="btn btn-icon" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
              <i class="bx bx-group fs-20 text-primary"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end pb-0">
              <table class="table mb-0">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Client name</th>
                </tr>
                </thead>
                <tbody>
                <tr class="align-middle" v-for="(client, i) in terminal.clients" :key="'client' + client.id">
                  <th>
                    {{ i + 1 }}
                  </th>
                  <td>
                    <router-link :to="{ name: 'client_profile', params: { company_name: client.slug } }">
                      {{ client.name }}
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <span v-else>--</span>
      </template>
      <template v-slot:manager="{ row: container }">
        <span v-if="container.manager">
          <router-link :to="{ name: 'user_profile', params: { slug: container.manager.slug } }">
            <div class="d-flex align-items-center">
              <div class="avatar-xxs flex-shrink-0 me-1">
                <span class="avatar-title bg-light text-primary rounded-circle">
                  {{ container.manager.username[0].toUpperCase() }}
                </span>
              </div>
              <span>{{ container.manager.username }}</span>
            </div>
          </router-link>
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:actions="props">
        <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
          <b-button variant="light" @click="updateContainer(props.row)" data-bs-target="#ContainerUpdate"
                    data-bs-toggle="modal">
            <i class="ri-pencil-line align-bottom fs-6"></i>
          </b-button>
          <b-button @click="deleteContainer(props.row)" variant="light">
            <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
          </b-button>
        </b-button-group>
      </template>
    </Table2>
  </div>
</template>
<script>
import Table2 from "@/components/tables/table.vue";
import ContainerImgView from "@/views/pages/terminal/models/ContainerImgView.vue";
import ContainerImgCreate from "@/views/pages/terminal/models/ContainerImgCreate.vue";
import ContainerDocCreate from "@/views/pages/terminal/models/ContainerDocCreate.vue";
import ContainerUpdate from "@/views/pages/terminal/models/ContainerUpdate.vue";
import ContainerDelete from "@/views/pages/terminal/models/ContainerDelete.vue";
import ContainerCreate from "@/views/pages/terminal/models/ContainerCreate.vue";
import TerminalActions from "@/views/pages/terminal/components/TerminalActions.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: 'terminal_containers',
  components: {
    Table2,
    ContainerImgCreate,
    ContainerDocCreate,
    ContainerImgView,
    ContainerUpdate,
    ContainerDelete,
    ContainerCreate,
    TerminalActions
  },
  data() {
    return {
      table: {
        name: 'Containers in Terminal',
        url: '/terminal/containers/',
        headers: [
          {
            field: 'id',
            label: 'ID',
            align: 'center',
            visible: false,
            excel_data: (termminal) => {
              return termminal.id
            },
          },
          {
            field: 'train',
            label: 'Train',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.train ? termminal.train.name : ''
            },
          },
          {
            field: 'order',
            label: 'Order',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.order ? termminal.order.number : ''
            },
          },
          {
            field: 'container',
            label: 'Container',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.container.name
            },
          },
          {
            field: 'container_type',
            label: 'Type',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.container.type
            },
            searchType: 'select',
            searchOptions: [
              {value: '', label: ''},
              {
                value: '20',
                label: '20'
              },
              {
                value: '20HC',
                label: '20HC'
              },
              {
                value: '40',
                label: '40'
              },
              {
                value: '40HC',
                label: '40HC'
              },
              {
                value: '45',
                label: '45'
              }
            ],
          },
          {
            field: 'is_laden',
            label: 'Laden',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {value: '', label: ''},
              {
                value: 'true',
                label: 'Laden'
              },
              {
                value: 'false',
                label: 'Empty'
              }
            ],
            excel_data: (termminal) => {
              return termminal.is_laden ? 'Laden' : 'Empty'
            },
          },
          {
            field: 'wagon',
            label: 'Wagon',
            align: 'center'
          },
          {
            field: 'terminal',
            label: 'Terminal',
            align: 'center',
            searchType: 'select',
            searchOptions: [],
            excel_data: (termminal) => {
              return termminal.terminal ? termminal.terminal.name : ''
            },
          },
          {
            field: 'container_status',
            label: 'Status',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {value: '', label: ''},
              {
                value: 'available',
                label: 'Available'
              },
              {
                value: 'pending',
                label: 'Pending'
              },
              {
                value: 'dispatched',
                label: 'Dispatched'
              }
            ]
          },
          {
            field: 'customers',
            label: 'Customers',
            align: 'center',
            maxWidth: '300px'
          },
          {
            field: 'storage_cost',
            label: 'Storage Cost',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.storage_cost || 0
            },
            searchable: false
          },
          {
            field: 'additional_cost',
            label: 'Additional Cost',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.additional_cost || 0
            },
          },
          {
            field: 'total_cost',
            label: 'Total Cost',
            align: 'center',
          },
          {
            field: 'bl',
            label: 'Bl',
            align: 'center',
            visible: false,
          },
          {
            field: 'gu',
            label: 'Gu',
            align: 'center',
            visible: false
          },
          {
            field: 'container_owner',
            label: 'Container Owners',
            align: 'center',
            visible: false,
            excel_data: (termminal) => {
              return termminal.container_owner ? termminal.container_owner.length : ''
            },
          },
          {
            field: 'seal_images',
            label: 'Seal Images',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.seal_images ? termminal.seal_images.length : ''
            },
            searchable: false
          },
          {
            field: 'documents',
            label: 'Documents',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.documents ? termminal.documents.length : ''
            },
            searchable: false
          },
          {
            field: 'clients',
            label: 'Clients',
            align: 'center',
            visible: false,
            excel_data: (termminal) => {
              return termminal.clients ? termminal.clients.length : ''
            },
          },
          {
            field: 'arrival_date',
            label: 'Arrival Date',
            align: 'center'
          },
          {
            field: 'dispatch_date',
            label: 'Dispatch Date',
            align: 'center'
          },
          {
            field: 'application_bot',
            label: 'Application Bot',
            align: 'center',
            visible: false,
            excel_data: (termminal) => {
              return termminal.application_bot ? '📅' : ''
            },
          },
          {
            field: 'manager',
            label: 'manager',
            align: 'center',
            excel_data: (termminal) => {
              return termminal.manager ? termminal.manager.username || 'Unknown User' : ''
            },
          },
          {
            field: 'actions',
            label: 'Actions',
            align: 'center'
          }
        ],
        searchable: true,
        getUpdate: true
      },

      container_img: {
        images: [],
        container_id: 0
      },

      row_id: 0,

      container_update: {},

      order_child_types: {
        container_order: '/orders/container/view/',
        wagon_order: '/orders/wagon/view/',
        empty_wagon_order: '/orders/empty-wagon/view/'
      },

      show_table: false
    }
  },
  methods: {
    viewContainerImg(row) {
      this.$refs.ContainerImgViewModal.showImages(row.container.id, row.seal_images)
      // this.container_img.images = row.seal_images
      // this.container_img.container_id = row.container.id
    },
    downloadDocument(file) {
      let url = process.env.VUE_APP_ORDER_URL + file
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    async deleteDocument(document_id) {
      let response = await axios.delete('/terminal/containers/document/' + document_id + '/delete/')
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (response.status === 204) {
        this.table.getUpdate = !this.table.getUpdate
        await Toast.fire({
          icon: 'success',
          title: 'Document deleted successfully',
        })
      } else {
        await Toast.fire({
          icon: 'error',
          title: 'Document deletion failed',
        })
      }
    },
    updateContainer(row) {
      this.container_update = {
        id: row.id,
        container: row.container,
        status: row.container_status,
        terminal: row.terminal,
        train: 1,
        is_laden: row.is_laden,
        arrival_date: row.arrival_date,
        dispatch_date: row.dispatch_date,
        bl: row.bl,
        gu: row.gu,
      }
    },
    deleteContainer(row) {
      this.$refs.containerDelete.showModal({
        id: row.id,
        container: row.container
      })
    },
    async updateTable() {
      await this.$refs.terminalTable.getData()
    },

    onRowSelect(event) {
      this.$refs.terminalActions.selected_rows = (event || []).map(item => {
        return {
          container: item.container,
          wagon: item.wagon,
          terminal: item.terminal,
          arrival_date: item.arrival_date,
          customers: item.customers || []
        }
      })
      this.selected_rows = event || 0
    },

    async getTerminalOptions() {
      try {
        let response = await axios.get('/terminal/')
        let search_options = this.table.headers.find(i => i.field === 'terminal').searchOptions
        search_options.push({
          value: '',
          label: 'All'
        })
        response.data.results.forEach(item => {
          search_options.push({
            value: item.name,
            label: item.name
          })
        })
      } catch {
        this.table.headers.find(i => i.field === 'terminal').searchType = undefined
      }
      this.show_table = true
    },
    async filterByStatus(status) {
      const currentQuery = Object.assign({}, this.$route.query);
      if ('container_status' in currentQuery && (currentQuery.container_status || '') === status) {
        delete currentQuery.container_status;
        this.$router.push({query: currentQuery});
      } else {
        this.$router.push({query: {...currentQuery, container_status: status}});
      }
    },

    formatPrice(price) {
      if (!price) return 0
      return parseFloat(price).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    calculateChargedDays(container) {
      if (container.free_days_left > 0) return 0
      return ((container.number_of_days || 0) - container.terminal.free_days) > 0 ? (container.number_of_days || 0) - container.terminal.free_days : 0
    }
  },
  mounted() {
    this.getTerminalOptions()
  }
}
</script>